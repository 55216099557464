import { LoadingScreen } from '@segunosoftware/equinox';
import { useAccountLoader } from '../hooks/useAccount';

export type ProtectedRouteProps = {
	element: JSX.Element;
};

export default function ProtectedRoute({ element }: ProtectedRouteProps) {
	const { account, isLoadingAccount } = useAccountLoader();

	if (!account || isLoadingAccount) {
		return <LoadingScreen />;
	}

	return element;
}
